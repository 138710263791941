<template>
  <div class="profile-container">
    <div class="page-container">
      <RouterView></RouterView>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'Profile',
  data: () => ({
    profile: '',
  }),
  async created() {
    if (this.currentPlanId === null) await this.getUserData();
    if (this.hasNoPlans) await this.getPlans();
  },
  computed: {
    ...mapState('profileGeneral', ['currentPlanId']),
    ...mapGetters('profileGeneral', ['hasNoPlans']),
  },
  methods: {
    ...mapActions('profileGeneral', ['getUserData', 'getPlans']),
  },
};
</script>

<style lang="scss">
html > body {
  padding-top: 0 !important;
}

.profile-container {
  width: 100%;
  height: 1000px;
  display: flex;
  justify-content: center;
}
.page-container {
  max-width: 1200px;
  flex-grow: 1;
  height: 100%;
}
</style>
